export const foodPhotos = [
    [
        "food/IMG_7641.jpg",
        "food/IMG_8468.jpg",
        "food/IMG_7806.jpg",
        "food/IMG_7684.jpg",
    ],
    [
        "food/IMG_8055.jpg",
        "food/IMG_7886.jpg",
        "food/IMG_7942.jpg",
        "food/IMG_7759.jpg",
    ],
    [
        "food/IMG_7841.jpg",
        "food/IMG_6811.jpg",
        "food/IMG_0384.jpg",
        "food/IMG_8170.jpg",
    ],
    [
        "food/IMG_8903.jpg",
        "food/IMG_3921.jpg",
        "food/IMG_9408.jpg",
        "food/IMG_8104.jpg",
    ],
    [
        "food/IMG_9398.jpg",
        "food/IMG_9634.jpg",
        "food/IMG_0344.jpg",
        "food/IMG_9373.jpg",
    ],
    [
        "food/IMG_8628.jpg",
        "food/IMG_8836.jpg",
        "food/IMG_9512.jpg",
        "food/IMG_7788.jpg",
    ],
    [
        "food/IMG_3259.jpg",
        "food/IMG_7928.jpg",
        "food/IMG_8305.jpg",
        "food/IMG_8392.jpg",
    ]
];
  